<template>
  <div>
    <section class="section bg-neutral-200 overflow-hidden" style="padding-bottom: 0">
      <div class="container-default w-container">
        <div class="responsive-container _768px---tablet">
          <div class="w-layout-grid grid-2-columns section-layout-v2-grid-2-col" style="align-items: flex-start">
            <div id="w-node-d0894be0-f2de-10ba-44aa-af44ddbc4d62-fdbc6cfb" class="inner-container _690px _100-tablet">
              <div id="w-node-_52a2ce10-a3f9-c001-e57c-688060b05819-fdbc6cfb" class="picture-wrapper alt"><img
                  class="scale-animation image-size-alt _01"
                  style="width: 100%; max-width: 100%; height: auto; object-fit: cover; object-position: center; border-radius: 8px;"
                  alt="VD Linde stukadoor"
                  data-aos="fade-up" data-aos-duration="1000"
                  src="/images/foto-bus.jpeg">
              </div>
            </div>
            <div id="w-node-d04b961d-790e-fee1-831d-ef28dc8a697e-fdbc6cfb" class="inner-container _510px _100-tablet">
              <div class="text-center---tablet">
                <h1 class="page-title"><b style="font-weight: 800"> Brian van der Linde</b></h1>
                <span class="stripe"></span>
                <br>
                <p>
                  In 2023 begon ik, Brian van der Linde, mijn reis als zelfstandig stukadoor in de wereld van het
                  stucwerk. Met een scherp oog voor detail en een passie voor het ambacht, heb ik al menig ruimte
                  getransformeerd tot een ware parel van vakmanschap.</p>
                <br>
                <p>Ik streef ernaar om mijn klanten te
                  ontmoeten op hun terrein, waar werk en passie samenkomen. Vaak zul je mij aantreffen in mijn
                  werktenue, want voor mij zijn het de handen die het echte verhaal vertellen. Met mijn jarenlange
                  ervaring en inzicht weet ik precies hoe ik de wensen van mijn klanten kan omzetten in een prachtig resultaat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<style lang="scss">
.stripe {
  display: block;
  height: 4px;
  background: #44555F;
  border-radius: 5px;
  width: 120px;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 6px;
    height: 3px;
  }
}

.page-title {
  @media (max-width: 768px) {
    margin-top: 32px;
  }
}
</style>
