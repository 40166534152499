<template>
  <div class="footer">
    <div class="information-box">
      <div class="container">
        <div class="single-block">
          <h6>Locatie</h6>
          <a href="https://maps.app.goo.gl/7RN479QzLW6yXZJ6A" target="_blank">J. Broekhovenstraat 29 <br>
            Elburg, Gelderland</a>
        </div>
        <div class="single-block">
          <h6>Social media</h6>
          <div class="social-icons">
            <a href="https://wa.me/+31653461672" target="_blank">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_38_364)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M37.5155 6.39467C33.3978 2.27333 27.9235 0.00183333 22.0917 0C10.0742 0 0.293326 9.779 0.287826 21.802C0.285992 25.6447 1.29066 29.3957 3.19916 32.703L0.104492 44L11.6618 40.9677C14.8463 42.7057 18.4323 43.6205 22.0807 43.6223H22.0898C34.1055 43.6223 43.8882 33.8415 43.8937 21.8185C43.8973 15.994 41.6313 10.5142 37.5155 6.39467ZM22.0917 39.9392H22.0843C18.832 39.9392 15.6438 39.0647 12.8608 37.4128L12.199 37.0205L5.34049 38.819L7.17199 32.131L6.74116 31.4453C4.92616 28.5597 3.96916 25.2248 3.97099 21.802C3.97466 11.8103 12.1055 3.68133 22.1008 3.68133C26.9408 3.68133 31.4912 5.56967 34.9122 8.99433C38.3332 12.4208 40.216 16.973 40.2142 21.8148C40.2087 31.8102 32.0797 39.9392 22.0917 39.9392ZM32.032 26.367C31.4875 26.0938 28.809 24.7757 28.3085 24.5942C27.8098 24.4127 27.4468 24.321 27.082 24.8655C26.7172 25.41 25.6758 26.6383 25.3568 27.0032C25.0397 27.3662 24.7207 27.412 24.1762 27.1388C23.6317 26.8657 21.8753 26.2918 19.7945 24.4347C18.1757 22.99 17.0812 21.2062 16.764 20.6598C16.4468 20.1135 16.731 19.8202 17.0023 19.5488C17.248 19.305 17.5468 18.9127 17.82 18.5937C18.095 18.2783 18.1848 18.051 18.3682 17.6862C18.5497 17.3232 18.4598 17.0042 18.3223 16.731C18.1848 16.4597 17.0958 13.7775 16.643 12.6867C16.2012 11.6233 15.752 11.7682 15.4165 11.7517C15.0993 11.7352 14.7363 11.7333 14.3715 11.7333C14.0085 11.7333 13.4182 11.869 12.9195 12.4153C12.4208 12.9617 11.0128 14.2798 11.0128 16.9602C11.0128 19.6423 12.9653 22.2328 13.2367 22.5958C13.508 22.9588 17.0775 28.4625 22.5427 30.822C23.8425 31.383 24.8582 31.7185 25.6483 31.9697C26.9537 32.384 28.1417 32.3253 29.0803 32.186C30.1272 32.0302 32.3033 30.8678 32.758 29.5955C33.2127 28.3232 33.2127 27.2305 33.0752 27.005C32.9395 26.7758 32.5765 26.6402 32.032 26.367Z"
                        fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_38_364">
                    <rect width="44" height="44" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a href="https://www.instagram.com/vdlindeafbouw/" target="_blank">
              <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.9297 11.5439C15.6868 11.5439 10.6512 16.5806 10.6512 22.825C10.6512 29.0693 15.6868 34.106 21.9297 34.106C28.1727 34.106 33.2082 29.0693 33.2082 22.825C33.2082 16.5806 28.1727 11.5439 21.9297 11.5439ZM21.9297 30.1591C17.8954 30.1591 14.5972 26.87 14.5972 22.825C14.5972 18.7799 17.8855 15.4908 21.9297 15.4908C25.9739 15.4908 29.2622 18.7799 29.2622 22.825C29.2622 26.87 25.9641 30.1591 21.9297 30.1591ZM36.3003 11.0825C36.3003 12.5454 35.1224 13.7137 33.6696 13.7137C32.207 13.7137 31.0389 12.5355 31.0389 11.0825C31.0389 9.62937 32.2168 8.45119 33.6696 8.45119C35.1224 8.45119 36.3003 9.62937 36.3003 11.0825ZM43.7702 13.753C43.6033 10.2283 42.7984 7.10611 40.2168 4.53375C37.645 1.9614 34.5236 1.15631 30.9997 0.979587C27.3678 0.773406 16.4819 0.773406 12.85 0.979587C9.33585 1.1465 6.21438 1.95158 3.63278 4.52394C1.05119 7.09629 0.256093 10.2185 0.0794059 13.7432C-0.126729 17.3759 -0.126729 28.2642 0.0794059 31.8969C0.246277 35.4216 1.05119 38.5438 3.63278 41.1161C6.21438 43.6885 9.32603 44.4936 12.85 44.6703C16.4819 44.8765 27.3678 44.8765 30.9997 44.6703C34.5236 44.5034 37.645 43.6983 40.2168 41.1161C42.7886 38.5438 43.5935 35.4216 43.7702 31.8969C43.9763 28.2642 43.9763 17.3857 43.7702 13.753ZM39.0782 35.7947C38.3125 37.7191 36.8303 39.2016 34.8966 39.9772C32.0009 41.126 25.1297 40.8609 21.9297 40.8609C18.7297 40.8609 11.8487 41.1161 8.96284 39.9772C7.03891 39.2114 5.55671 37.7289 4.78125 35.7947C3.63278 32.8984 3.89781 26.0257 3.89781 22.825C3.89781 19.6242 3.6426 12.7417 4.78125 9.85519C5.54689 7.93083 7.0291 6.44829 8.96284 5.67266C11.8585 4.52394 18.7297 4.78903 21.9297 4.78903C25.1297 4.78903 32.0107 4.53375 34.8966 5.67266C36.8205 6.43847 38.3027 7.92101 39.0782 9.85519C40.2266 12.7515 39.9616 19.6242 39.9616 22.825C39.9616 26.0257 40.2266 32.9082 39.0782 35.7947Z" fill="white"/>
              </svg>
            </a>
          </div>
        </div>
        <div class="single-block">
          <h6>Bereikbaar via:</h6>
          <a href="mailto:vdlindeafbouw@gmail.com" target="_blank">vdlindeafbouw@gmail.com</a>
          <a href="tel:0653461672" target="_blank">06 53461672</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

$gold: #D6AD60;
$gold-d2: #9a7836;
$coal: #122620;


.footer {
  margin-top: 120px;
  width: 100%;


  .information-box {
    background-color: #44555F;
    padding: 100px 0 50px;
    @media (max-width: 1100px) {
      padding: 70px 0;
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      justify-items: stretch;
      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }

      .single-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .social-icons {
          display: flex;
          gap: 30px;
        }

        h6, p, a {
          text-align: center;
          font-family: 'Sofia Pro', sans-serif;
          color: white;
        }

        h6 {
          font-size: 40px;
          margin-bottom: 10px;
        }

        a {
          opacity: .7;
          transition: .3s;
          font-size: 30px;
          line-height: 35px;
          text-decoration: none;
          &:hover {
            opacity: 1;
          }
        }

        svg {
          cursor: pointer;
          transition: .3s;

          &:hover {
            * {
              opacity: 1;
            }
          }

          * {
            cursor: pointer;
            fill: white;
            transition: .3s;
            opacity: .8;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'FooterBar',
}
</script>
