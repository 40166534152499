<template>
  <div class="landing">
    <div class="position-relative overflow-hidden">
      <section class="section hero v1 home-hero">
        <div class="container-default w-container">
          <div>
            <div
                class="mg-bottom-60px">
              <div class="inner-container _650px---tablet center">
                <div class="flex-vertical center text-center">
                  <div class="inner-container _790px">
                    <div class="inner-container _500px---mbl center"><h1
                        data-aos="zoom-in" data-aos-duration="1000"
                        class="display-1 color-neutral-100 mg-bottom-21px">Stucwerk op zijn best. VD Linde afbouw</h1>
                    </div>
                  </div>
                  <div class="inner-container _700px"><p class="color-neutral-400 mg-bottom-35px">Met vakmanschap en
                    passie leveren wij stucwerk van topkwaliteit voor zowel particulieren als bedrijven. Ontdek hoe wij
                    jouw project kunnen transformeren met onze expertise.</p></div>
                </div>
              </div>
            </div>
            <div class="w-layout-grid grid-3-columns hero-v1-grid">
              <div id="w-node-b9d11e92-b7d5-fc52-f441-ed76ed795747-fdbc6d52" class="home-v1-picture"><img
                  data-aos="fade-up" data-aos-duration="1000"
                  class="scale-animation fit-cover _w-h-100"
                  src="/images/stuc5.webp"
              >
              </div>
              <div id="w-node-_0661b60f-073c-ec9d-c2c5-98df6b882cb1-fdbc6d52" class="home-v1-picture _01"><img
                  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
                  class="scale-animation fit-cover _w-h-100"
                  src="/images/stuc6.webp"
              >
              </div>
              <div id="w-node-_94303314-be8d-456f-6f8c-7cc152b43d90-fdbc6d52" class="home-v1-picture hidden-on-mbl"><img
                  data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000"
                  class="scale-animation fit-cover _w-h-100"
                  src="/images/stuc8.jpeg"
              >
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="half-bg-top home-v1-bg-top" style="z-index: 0"></div>
    </div>

    <section class="section overflow-hidden">
      <div class="container-default w-container">
        <div>
          <div class="w-layout-grid grid-2-columns section-layout-grid-2-col">
            <div id="w-node-_4768bd8a-b1d9-ede9-c0c0-55a036404294-fdbc6d52"
                 class="w-layout-grid grid-1-column section-layout-grid-1-col">
              <div id="w-node-_89bbbeb6-2f41-a2b7-0622-22f6d0161c0c-fdbc6d52"
                   class="image-full-left-width-wrapper section-layout-image-top">
                <div class="image-full-left-width section-layout-image-top"><img
                    data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000"
                    class="scale-animation fit-cover _w-h-100"
                    src="images/stuc4.webp"
                >
                </div>
              </div>
              <div id="w-node-a4cedf6f-104a-2844-8984-c41779d514c0-fdbc6d52" class="inner-container _566px _100-tablet">
                <p>
                </p>
                <p class="mg-bottom-0">Voor mij is stucwerk meer dan alleen een baan, het is mijn passie. Ik geniet
                  ervan om creatief te zijn met texturen en afwerkingen, en ik vind niets bevredigender dan het zien van
                  een project tot leven komen. <br><br>Laten we samenwerken om iets geweldigs te creëren!</p>
                <br>
                <router-link to="/contact" class="btn-secondary light w-button">Contact</router-link></div>
            </div>
            <div id="w-node-_5226f8c3-d3ed-4598-b6ab-b81462a4d670-fdbc6d52"
                 class="w-layout-grid grid-1-column section-layout-grid-1-col _02">
              <div id="w-node-_88964615-2b3f-8f96-aeb4-3ee874be8245-fdbc6d52" class="mg-left-auto mg-left-0-tablet">
                <div class="mg-bottom-24px">
                  <div class="subtitle-wrapper">
                    <div
                        class="subtitle">Over mij
                    </div>
                  </div>
                </div>
                <div>
                  <div class="inner-container _400px---mbl">
                    <div class="inner-container _600px---tablet"><h2 class="display-2 mg-bottom-6px">De stukadoor van
                      Elburg</h2></div>
                  </div>
                  <p class="mg-bottom-24px">Voor al uw binnen stucwerk. En buiten stucwerk beton cire renovatie/nieuwbouw.
                    <br><br>Welkom op mijn website! Mijn naam is Brian, en stucwerk is mijn vak. Met
                    jarenlange ervaring en een
                    scherp oog voor detail, bied ik maatwerkoplossingen die perfect passen bij jouw behoeften. <br><br>Ik
                    kan niet
                    wachten om mijn expertise met je te delen!</p>
                  <router-link to="/over-mij" class="btn-secondary light w-button">Meer over mij</router-link></div>
              </div>
              <div id="w-node-_2e1840ff-6310-97e3-091f-9d45239ebc68-fdbc6d52"
                   class="card-picture section-layout-image-bottom"><img class="scale-animation fit-cover _w-h-100"
                                                                         src="images/stuc5.webp">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-neutral-200 overflow-hidden" style="padding: 0">
      <div class="container-default w-container">
        <div>
          <div class="mg-bottom-60px">
            <div class="flex-vertical center text-center">
              <div class="inner-container _534px">
                <div class="inner-container _138px center">
                  <div class="mg-bottom-24px">
                    <div  class="subtitle-wrapper">
                      <div class="subtitle"
                           style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d; opacity: 1;">
                        Instagram
                      </div>
                    </div>
                  </div>
                </div>
                <h2  class="mg-bottom-0" style="font-weight: 700">
                  Check mijn insta account ;)</h2></div>
            </div>
          </div>
          <div class="mg-bottom-60px">
            <div class="w-layout-grid grid-2-columns instagram-gallery-grid-2-col"><a
                 href="https://www.instagram.com/vdlindeafbouw" target="_blank"
                class="instagram-feed---image-wrapper w-inline-block"><img
                src="/images/stuc4.webp"
                class="scale-animation instagram-feed---image">
              <div class="instagram-feed---image-overlay" style="opacity: 0;"><img
                  src="https://assets-global.website-files.com/644be2808c71a50680612eed/644be2808c71a50bdb613088_instagram-icon-white-brix-templates.svg"
                  loading="eager" alt="Instagram Icon - Suite X Webflow Template"
                  class="instagram-feed---icon-inside v1"
                  style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg); transform-style: preserve-3d;">
              </div>
              <div class="w-layout-grid"></div>
            </a>
              <div id="w-node-cc828c34-f2ef-1f7f-78a2-aa085f8578ef-7b21d921"
                   class="w-layout-grid grid-2-columns instagram-gallery-inner-grid-2-col"><a
                   href="https://www.instagram.com/vdlindeafbouw" target="_blank"
                  class="instagram-feed---image-wrapper w-inline-block"><img
                  src="/images/stuc5.webp"
                  class="scale-animation instagram-feed---image">
                <div class="instagram-feed---image-overlay" style="opacity: 0;"><img
                    src="https://assets-global.website-files.com/644be2808c71a50680612eed/644be2808c71a50bdb613088_instagram-icon-white-brix-templates.svg"
                    loading="eager" alt="Instagram Icon - Suite X Webflow Template"
                    class="instagram-feed---icon-inside v1"
                    style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                </div>
              </a><a  href="https://www.instagram.com/vdlindeafbouw" target="_blank"
                     class="instagram-feed---image-wrapper w-inline-block"><img
                  src="/images/stuc6.webp"
                  class="scale-animation instagram-feed---image"> <div class="instagram-feed---image-overlay" style="opacity: 0;"><img
                    src="https://assets-global.website-files.com/644be2808c71a50680612eed/644be2808c71a50bdb613088_instagram-icon-white-brix-templates.svg"
                    loading="eager" alt="Instagram Icon - Suite X Webflow Template"
                    class="instagram-feed---icon-inside v1"
                    style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                </div>
              </a><a  href="https://www.instagram.com/vdlindeafbouw" target="_blank"
                     class="instagram-feed---image-wrapper w-inline-block"><img
                  src="/images/stuc9.jpeg"
                  class="scale-animation instagram-feed---image">   <div class="instagram-feed---image-overlay" style="opacity: 0;"><img
                    src="https://assets-global.website-files.com/644be2808c71a50680612eed/644be2808c71a50bdb613088_instagram-icon-white-brix-templates.svg"
                    loading="eager" alt="Instagram Icon - Suite X Webflow Template"
                    class="instagram-feed---icon-inside v1"
                    style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                </div>
              </a><a  href="https://www.instagram.com/vdlindeafbouw" target="_blank"
                     class="instagram-feed---image-wrapper w-inline-block"><img
                  src="/images/stuc7.jpeg"
                  class="scale-animation instagram-feed---image">    <div class="instagram-feed---image-overlay" style="opacity: 0;"><img
                    src="https://assets-global.website-files.com/644be2808c71a50680612eed/644be2808c71a50bdb613088_instagram-icon-white-brix-templates.svg"
                    loading="eager" alt="Instagram Icon - Suite X Webflow Template"
                    class="instagram-feed---icon-inside v1"
                    style="opacity: 0; transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.01) rotateX(0deg) rotateY(0deg) rotateZ(-4deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                </div>
              </a></div>
            </div>
          </div>
          <div  class="flex-horizontal" style="opacity: 1;"><a
              href="https://www.instagram.com/vdlindeafbouw" target="_blank" class="btn-primary w-button">Volg mij</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

// @ is an alias to /src
import TextImage from '@/components/TextImage.vue'
import Voordelen from "@/components/Voordelen";
import AOS from "aos";

export default {
  name: 'HomeView',
  components: {
    Voordelen,
    TextImage
  },
  data() {
    return {
      showedFaq: false,
    }
  },
  methods: {
    toggleFaq(id) {
      console.log(id)
      let selectedFaq = document.getElementById('faq-' + id)

      if (selectedFaq.classList.contains('active-faq')) {
        selectedFaq.classList.remove('active-faq')
      } else {
        selectedFaq.classList.add('active-faq')
      }

      this.showedFaq = !this.showedFaq
    }
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
