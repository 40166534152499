<template>
  <section class="text-image" :class="{ flipped: flip }">
    <div class="container">
      <div class="img-side backdrop backdrop-xl">
        <slot name="image"></slot>
      </div>
      <div class="text-side">
        <slot name="title"></slot>
        <slot name="text"></slot>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
body {
  .flipped {
    background-color: #222222;
    padding: 80px 0;
    margin-top: 100px;

    .container {
      flex-direction: row;
      @media (max-width: 1200px) {
        flex-direction: column;
      }

      .text-side {
        h2 {
          color: white;
        }
        p {
          color: white;
          opacity: .8;
        }
      }
    }
  }
}
.text-image {
  margin-top: 100px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
    @media (max-width: 1500px) {
      gap: 70px;
    }

    @media (max-width: 1400px) {
      img {
        max-width: 550px !important;
      }
    }
    @media (max-width: 1150px) {
      flex-direction: column;
    }

    .img-side {
      height: fit-content;
      width: fit-content;
      @media (max-width: 900px) {
        width: 100%;
      }
      img {
        object-fit: cover;
        max-width: 750px;
        @media (max-width: 900px) {
          width: 100%;
          max-width: unset !important;
        }
      }
    }
    .text-side {
      .page-title {
        font-weight: 400;
        margin-bottom: 15px;
      }
      .stripe {
        display: block;
        height: 4px;
        background: #44555F;
        border-radius: 5px;
        width: 120px;
      }

      p {
        margin-top: 15px;
        font-size: 16px;
        color: #6B87A4;
      }

      button {
        margin-top: 20px;
        padding: 5px 35px;
        font-size: 13px;
        background-color: #44555F;
        border: none;
        color: white;
        border-radius: 4px;
        font-family: 'Avenir', sans-serif;
      }
    }
  }

}
</style>

<script>
export default {
  name: 'TextImage',
  props: {
    flip: Boolean
  }
}
</script>
