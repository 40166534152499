import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
    startEvent: 'load',
    once: true
});

import VueMeta from 'vue-meta'

createApp(App).use(router).mount('#app')
