<template>
  <navigation></navigation>
  <transition name="fade" mode="out-in">
    <router-view/>
  </transition>
  <footer-bar></footer-bar>
</template>

<style lang="scss">

.fade-enter-from, .fade-leave-from {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s ease-out;
}

$blue: #44555F;
$blue-d2: #1c5e93;
$coal: #122620;

html, body {
  margin: 0;
  padding: 0;
  background-color: #FBFBFB;
  width: 100%;
}

.container {
  max-width: 1440px;
  padding: 20px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1600px) {
    max-width: 1440px;
  }
}

.blackdrop {
  &::after {
    //background-color: $coal !important;
  }
}

.backdrop {
  position: relative;
  z-index: 5;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: $blue;
    position: absolute;
    z-index: -1;
  }
}

.cta-div {
  position: absolute;
  left: 25px;
  bottom: 20px;
  z-index: 5;

  span {
    color: #E5CE8B;
  }

  h4 {
    color: white;
    font-size: 32px;
    margin-bottom: 5px;
    line-height: 35px;
    @media (max-width: 1200px) {
      font-size: 25px;
      line-height: 22px;
    }
    @media (max-width: 950px) {
      font-size: 21px;
      line-height: 17px;
      padding-right: 5px;
    }

    @media (max-width: 750px) {
      font-size: 25px;
      line-height: 22px;
    }

  }

  svg {
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: $blue;
    padding: 10px;
    transition: .3s;

    &:hover {
      background-color: $blue-d2;
    }
  }
}

.black-backdrop {
  &::after {
    background-color: $coal;
  }
}

.backdrop-m {
  &::after {
    left: 6px;
    top: 6px;
  }
}

.backdrop-xl {
  &::after {
    left: 8px;
    top: 8px;
  }
}

.backdrop-s {
  &::after {
    left: 7px;
    top: 7px;
  }
}

h1, h2, h3, h4, h5, h6, p, a, li {
  margin: 0;
}
ul {
  padding: 0;
}
li {
  font-weight: 500;
  font-size: 22px;
}

.sofia {
  font-family: 'Avenir LT Std', sans-serif;
}

h1, h2, h3, h4, h5, h6, b {
  color: #2C3E50;
  font-family: 'Avenir LT Std', sans-serif;

}

p, a {
  font-family: 'Open Sans', sans-serif;
}

h1, h2 {
  font-size: 45px;
  line-height: 50px;
  @media (max-width: 1220px) {
    font-size: 35px;
    line-height: 40px;
  }
  @media (max-width: 720px) {
    font-size: 32px;
    line-height: 30px;
  }
}

h3 {
  font-size: 40px;
  line-height: 40px;
}

h5 {
  font-size: 30px;
  font-weight: 600;
  @media (max-width: 1400px) {
    font-size: 22px;
  }
}

p {
  font-size: 25px;
  @media (max-width: 1400px) {
    font-size: 22px;
  }
  @media (max-width: 1220px) {
    font-size: 18px;
  }
}

div {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.card-carousel {
  user-select: none;
  position: relative;
}

.progressbar {
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: rgba(221, 221, 221, 0.25);
  z-index: 1;
}

.progressbar > div {
  background-color: rgba(255, 255, 255, 0.52);
  height: 100%;
}

.thumbnails {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}

.thumbnail-image {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
}

.thumbnail-image > img {
  width: 100%;
  height: auto;
  transition: all 250ms;
}

.thumbnail-image:hover > img,
.thumbnail-image.active > img {
  opacity: 0.6;
  box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.5);
}

.card-img {
  position: relative;
  margin-bottom: 15px;
}

.card-img > img {
  display: block;
  margin: 0 auto;
}

.actions {
  font-size: 1.5em;
  height: 40px;
  position: absolute;
  top: 50%;
  margin-top: -20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #585858;
}

.actions > span {
  cursor: pointer;
  transition: all 250ms;
}

.actions > span.prev {
  margin-left: 5px;
}

.actions > span.next {
  margin-right: 5px;
}

.actions > span:hover {
  color: #eee;
}

.watch-more-button {
  background-color: $blue;
  color: white;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 5px;
  width: fit-content;
  text-decoration: none;
  font-weight: 600;

  svg {
    width: 15px;
    height: 11px;
    margin-top: 1px;
  }

  &:hover {
    background-color: $blue-d2;
  }
}

</style>
<script>
import Navigation from "@/components/Navigation";
import FooterBar from "@/components/FooterBar";

export default {
  components: {FooterBar, Navigation},
  methods: {}
}
</script>
