import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import About from "@/views/About";
import contact from "@/views/Contact";

function lazyLoad(view) {
    return () => import(`@/views/${view}.vue`)
}

const routes = [
    {
        path: '/',
        name: 'home',
        props: true,
        component: HomeView,
        meta: {
            title: "VD Linde afbouw | Vakmanschap in stucwerk | Brian van der Linde"
        }
    },
    {
        path: '/over-mij',
        name: 'OverMij',
        props: true,
        component: lazyLoad('About'),
        meta: {
            title: "Over mij | VD Linde afbouw"
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        props: true,
        component: contact,
        meta: {
            title: "Contact | VD Linde afbouw"
        }
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})


router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    document.title = `${to.meta.title}`;
    next();
})

export default router
